
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'Tab',
})
export default class Tab extends Vue {
  @Prop({ type: String }) href!: string;

  @Emit()
  clicked(event: any) {
    return event;
  }

  @Emit('change')
  changed(event: any) {
    return event;
  }
}
