
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'Tabs',
})
export default class Tabs extends Vue {
  @Prop({ type: String }) value!: string;
  @Prop({ type: Boolean, default: false }) fixedTabs!: boolean;

  @Emit('input')
  public update(value: string) {
    return value;
  }

  @Emit('input')
  @Emit('change')
  changed(event: any) {
    return event;
  }
}
