
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'TabsItems',
})
export default class TabsItems extends Vue {
  @Prop({ type: String }) value!: string;

  @Emit('input')
  public update(value: string) {
    return value;
  }
}
